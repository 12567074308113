@import '@/components/variables.scss';

.simple {

  &-container {
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    
  }
  
  &-item {
    
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    padding-left: spacer(lg);
    padding-right: spacer(lg);
    padding-top: spacer(base);
    padding-bottom: spacer(lg);
    position: relative;
    
    label {
      display: block;
      color: var(--vinPlateTabText);
    }
    
  }
  
  &-bar {
    
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 5px;
    background-color: var(--primaryColor);
    transition: all 0.5s ease;
    
  }
  
  &-selected {
    left: 0;
    width: 100%;
  }
    

}

