@import '@/components/variables.scss';

.main {
  display: block;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  padding-top: spacer(xl);
  background-color: var(--bodyBg, $x-light-bg-color);
  color: var(--bodyText, #000000);
}
.layout {
  display: flex;
  flex-direction: row;
  gap: spacer(xl);
  justify-content: center;
  @media screen and (max-width: $x-breakpoint-xs) {
    flex-direction: column;
  }
  > div {
    flex: 0 0 auto;
  }
  &-add {
    width: 100%;
    max-width: 420px;
  }
}
.panel {
  display: block;
  width: 100%;
  max-width: var(--maxWidth);
  padding: 32px;
  margin: auto;
  position: relative;
}
.main_logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    width: 240px;
    max-width: calc(100vw - spacer(xl))
  }
}
.title {
  display: block;
  text-align: center;
  font-size: 2.5rem;
  line-height: 2.7rem;
  color: var(--primaryColor);
  @media screen and (max-width: $x-breakpoint-xs) {
    font-size: 1.6rem;
    line-height: 1.8rem;
    + .subtitle {
      padding-top: spacer(base);
    }
  }
  &-primary {
    color: var(--primaryColor);
  }
}
.subtitle {
  display: block;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-weight: 400;
  padding-top: spacer(base);
  @media screen and (max-width: $x-breakpoint-xs) {
    font-size: 1rem;
    line-height: 1.1rem;
  }
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  gap: 8px;
  img {
    display: block;
    height: 32px;
  }
}

.tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: spacer(xl);
  align-items: center;
  justify-content: center;
}

.tile {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: var(--tileWidth);
  height: 300px;
  // box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border: 1px solid $x-border-color;
  border-radius: spacer(base);
  background-color: white;
  overflow: hidden;
  color: #000000;

  @media screen and (max-width: $x-breakpoint-xs) {
    width: calc((100vw - 64px));
    height: auto;
  }

  &-button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  &-bid {
    background-color: var(--highBidBackground);
  }

}

.offer {
  position: relative;
  padding: spacer(xl);
  width: 100%;
  height: 100%;

  button {
    color: black !important;
  }

  &-quote {
    flex: 0 0 auto;
    min-width: 120px;
    font-size: 2.5rem;
    font-weight: 700;
    padding-bottom: spacer(base);
    text-align: left;
    color: black;

    img {
      opacity: 0.3;
      height: 44px;
    }
    svg {
      height: 32px;
      width: 32px;
      color: var(--colorRed);
    }
  }
  &-provider {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: 32px;
    min-width: 120px;
    text-align: left;
    img {
      flex: 0 0 auto;
      max-height: 32px;
    }
    &-placeholder {
      opacity: 0.3;
      margin-left: -12px;
    }
    &-hidden {
      font-weight: 700;
      font-size: 1.1rem;
      color: #000000;
    }
  }

  &-loading {

    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  
    &::after {
      content: "";
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(0,0,0,0) 10%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 90%);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      animation: loadingAnimation 2s both;
      animation-iteration-count: infinite;
    }
  
  }

  &-bullets {
    display: block;
    padding-top: spacer(base);
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
      text-align: left;
      font-size: 1rem;
      color: black;
      font-weight: 400;
      &:first-child {
        margin-top: 0;
      }
      i {
        flex: 0 0 auto;
        padding-right: 4px;
        color: #DD0000;
        svg {
          width: 16px;
          height: 12px;
        }
      }
      span {
        flex: 1 1 auto;
      }
      p {
        flex: 1 1 auto;
        color: #999999;
        font-size: 14px;
      }
    }
    &_check {
      color: #038909 !important;
    }
  }

  &-highbid {
    background-color: var(--highBidBackground);
    .offer-quote {
      color: $x-color-yellow;
    }
    .offer-bullets span {
      color: var(--highBidForeground);
    }
  }

  &-highbid-title {
    display: block;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: $x-color-red;
    svg {
      color: $x-color-red !important;
      height: 32px;
      width: 30px;
      margin-bottom: -4px;
    }
  }

  &-banner {
    display: block;
    width: 100%;
    margin-top: spacer(base);
    background-color: var(--secondaryColor);
    color: var(--secondaryText);
    padding: spacer(xs);
    font-size: 1rem;
    font-weight: 700;

    &-top {
      background-color: var(--topOfferBannerBg);
      color: var(--topOfferBannerText);
    }
    &-red {
      background-color: $x-color-red;
      color: white;
    }
    &-yellow {
      background-color: $x-color-yellow;
      color: #000000;
    }
    &-green {
      background-color: $x-color-green;
      color: white;
    }
    &-unverified {
      background-color: $x-color-gray;
      color: white;
    }
  }
  
}

.highbid {

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: spacer(xl);
  justify-content: flex-start;
  background-color: var(--highBidBackground);
  color: var(--highBidForeground);

  &-car {
    flex: 0 0 auto;
    font-weight: 700;
    color: white;
  }

  &-main {
    flex: 1 1 auto;
    padding-top: spacer(base);
    padding-bottom: spacer(base);
  }

  &-title {
    display: block;
    font-weight: 700;
    font-size: 2.4rem;
    color: $x-color-yellow;
    padding-bottom: spacer(base);
    svg {
      color: $x-color-red;
      height: 36px;
      width: 30px;
    }
  }

  &-none-title {
    font-weight: 700;
    font-size: 1.4rem;
  }

  &-buyer {
    display: block;
    font-weight: 700;
    color: var(--highBidButtonBackground);
    padding-bottom: spacer(sm);
  }

  &-msg {
    display: block;
  }

  &-button {
    flex: 0 0 auto;
    background-color: var(--highBidButtonBackground);
    color: var(--highBidButtonText);
    span {
      svg {
        color: var(--colorRed);
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-bottom: -6px;
      }
    }
  }

  &-secure {
    font-size: 1.5rem;
    color: var(--primaryColor);
    font-weight: 700;
  }

  &-form {
    display: block;
    max-width: 640px;
    margin: auto;
  }

  &-bullets {
    display: flex;
    flex-direction: column;
    gap: spacer(xl);
    &-row {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: spacer(xl);
    }
    &-marker {
      flex: 0 0 auto;
      border-radius: 999999px;
      background-color: var(--highBidForeground);
      color: var(--highBidBackground);
      display: flex;
      flex-direction: row;
      width: 72px;
      height: 72px;
      align-items: center;
      justify-content: center;
    }
    &-icon {
      flex: 0 0 auto;
      width: 72px;
      height: 72px;
      position: relative;
    }
    &-text {
      flex: 1 1 auto;
      color: var(--highBidForeground);
    }
  }
}

@keyframes loadingAnimation {
  
  0% {
    left: 0%;
    right: 100%;
  }

  100% {
    left: 100%;
    right: -100%;
  }

}  

.banner {
  
  display: block;
  border-radius: var(--tileBorderRadius);
  background-color: var(--selectedOfferBg);
  color: var(--selectedOfferText);
  padding: var(--bannerPadding);

  @media screen and (max-width: $x-breakpoint-xs) {
    padding: calc(var(--bannerPadding) * .75);
    font-size: .875rem;
  }

  &_img {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: calc(var(--tileBorderRadius) / 2);
    padding: 0 var(--tileBorderRadius);
    height: 44px;
    img {
      flex: 0 0 auto;
      display: block;
      width: 100%;
      height: auto;
      max-width: 120px;
      max-height: 36px;
      @media screen and (max-width: $x-breakpoint-xs) {
        max-width: 100px;
        max-height: 32px;
      }
    }
  }

  &_major {
    font-size: 2.2rem;
    font-weight: 700;
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 1.8rem;
    }
  }

  &_disclaimer {
    display: block;
    padding-top: spacer(base);
    font-size: .875rem;
    text-align: justify;
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: .675rem;
    }
  }

  &_msg {
    display: block;
    padding: spacer(base);
    background-color: rgba(255,255,255,0.2);
    font-size: 1rem;
    border-radius: calc(var(--tileBorderRadius) / 2);
    a {
      text-decoration: underline;
    }
  }

  &_button {
    @media screen and (max-width: $x-breakpoint-xs) {
      width: 100% !important;
    }
  }

  &-white {
    background-color: white;
  }
  
  &_text {
    color: var(--selectedOfferText);
  }

  &_rhtc {
    text-align: right;
    @media screen and (max-width: $x-breakpoint-xs) {
      text-align: left;
    }
  }

}

.best {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
